import Swiper from "swiper";
import SwiperAnimation from "@cycjimmy/swiper-animation";

const swiperAnimation = new SwiperAnimation();

new Swiper("header", {
  on: {
    init: function() {
      swiperAnimation.init(this).animate();
    },
    slideChange: function() {
      swiperAnimation.init(this).animate();
    }
  },
  effect: "fade",
  direction: "horizontal",
  loop: true,
  navigation: {
    nextEl: ".arrow--left",
    prevEl: ".arrow--right"
  }
});
