export default function toggle() {
  const toggleButtons = document.querySelectorAll("[data-toggle-button]");

  toggleButtons.forEach(el => {
    el.addEventListener("click", () => {
      const toggleEl = document.querySelector(
        `[data-toggle=${el.getAttribute("data-toggle-button")}]`
      );
      toggleEl.classList.toggle("hidden");
    });
  });
}
