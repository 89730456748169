import zenScroll from "zenscroll";

export default () => {
  
  const { pathname } = window.location;
  
    document.querySelectorAll(`a[href^="${pathname}#"]`).forEach(el => {
      console.log('#' + el.href.split('#')[1])
      el.href = '#' + el.href.split('#')[1];
    });
  
  zenScroll.setup(1000);
  setTimeout(() => {
    if(window.location.hash){
      var targetElem = document.getElementById(window.location.hash.substring(1))
      if (targetElem) {
        zenScroll.to(targetElem)
      }
    }
  }, 500);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 1);
}