import "./slider";
import toggle from "./toggle";
import AOS from "aos";
import scroll from "./scroll";

document.addEventListener("DOMContentLoaded", () => {
  toggle();
  AOS.init({disable: 'mobile'});
  scroll();
});
